@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Lato";
    src: url("./fonts/lato/Lato-Light.woff2") format("woff2"),
      url("./fonts/lato/Lato-Light.woff") format("woff");

    font-weight: 300;
  }

  @font-face {
    font-family: "Lato";
    src: url("./fonts/lato/Lato-Regular.woff2") format("woff2"),
      url("./fonts/lato/Lato-Regular.woff") format("woff");

    font-weight: 400;
  }

  @font-face {
    font-family: "Lato";
    src: url("./fonts/lato/Lato-Medium.woff2") format("woff2"),
      url("./fonts/lato/Lato-Medium.woff") format("woff");

    font-weight: 500;
  }

  @font-face {
    font-family: "Lato";
    src: url("./fonts/lato/Lato-Bold.woff2") format("woff2"),
      url("./fonts/lato/Lato-Bold.woff") format("woff");

    font-weight: 700;
  }
}

@layer components {
  .btn {
    @apply w-16 inline-flex justify-center border border-transparent bg-aegean-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-coral-700 focus:outline-none focus:ring-2 focus:ring-coral-500 focus:ring-offset-2;
  }
}
